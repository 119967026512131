import { faCar, faCoins, faGraduationCap, faUser, faUserCircle, faPhoneAlt, faFileSignature, faUsers, faHome, faIdCard, faComments, faUserEdit, faReceipt } from "@fortawesome/free-solid-svg-icons";
export var mainMenuList = [{
  type: 'item',
  menuItem: {
    title: 'Contul meu',
    link: '/login'
  },
  icon: faUserCircle,
  mobileOnly: true
}, {
  type: 'item',
  menuItem: {
    title: 'Asigurări RCA',
    link: '/asigurari-rca'
  },
  icon: faCar,
  mobileOnly: false
}, {
  type: 'list',
  menuItem: {
    title: 'Credite'
  },
  options: [{
    name: "Credite Nevoi Personale",
    link: "/credite-nevoi-personale"
  }, {
    name: "Credite Imobiliare",
    link: "/credite-imobiliare"
  }, {
    name: "Credite & Leasing Auto",
    link: "/credite-auto-leasing"
  }, {
    name: "Carduri de Credit",
    link: "/carduri"
  }, {
    name: "Credite Rapide",
    link: "/credite-rapide"
  }],
  icon: faCoins,
  mobileOnly: false
}, {
  type: 'list',
  menuItem: {
    title: 'Economisire'
  },
  options: [{
    name: "Depozite Bancare",
    link: "/depozite"
  }, {
    name: "Investiții",
    link: "/investitii"
  }, {
    name: "Pensii Private",
    link: "/pensii"
  }],
  icon: faCoins,
  mobileOnly: false
}, {
  type: 'list',
  menuItem: {
    title: 'Resurse utile'
  },
  options: [{
    name: "Curs valutar",
    link: "/cotatii"
  }, {
    name: "Dobânzi interbancare",
    link: "/rate-dobanzi-interbancare"
  }, {
    name: "Ghiduri financiare",
    link: "/ghiduri"
  }, {
    name: "Calculatoare",
    link: "/calculator"
  }, {
    name: "Vocea clientului",
    link: "/vocea-clientului"
  }, {
    name: "Articole",
    link: "/news"
  }, {
    name: "Instituții financiare",
    link: "/institutii-financiare"
  }, {
    name: "Banchereza",
    link: "/banchereza"
  }],
  icon: faGraduationCap
}, {
  type: 'item',
  menuItem: {
    title: 'Contact',
    link: '/contact'
  },
  icon: faPhoneAlt,
  mobileOnly: true
}];
export var termsMenuList = [{
  type: 'item',
  menuItem: {
    title: 'Contul meu',
    link: '/login'
  },
  icon: faUserCircle,
  mobileOnly: true
}, {
  type: 'list',
  menuItem: {
    title: 'Despre noi'
  },
  icon: faUsers,
  options: [{
    name: "Cine suntem?",
    link: "/despre-noi/cine-suntem"
  }, {
    name: "Istoric",
    link: "/despre-noi/istoric"
  }, {
    name: "Echipa",
    link: "/despre-noi/echipa"
  }, {
    name: "Produse",
    link: "/despre-noi/produse"
  }, {
    name: "Publicitate",
    link: "/despre-noi/publicitate"
  }, {
    name: "Cariera",
    link: "/despre-noi/cariera"
  }],
  mobileOnly: false
}, {
  type: 'list',
  menuItem: {
    title: 'Termeni legali'
  },
  icon: faFileSignature,
  options: [{
    name: "Termeni",
    link: "/termeni/generali"
  }, {
    name: "Termeni asigurări",
    link: "/termeni/asigurari"
  }, {
    name: "Petiții",
    link: "/termeni/petitii"
  }, {
    name: "Date personale",
    link: "/termeni/date-personale"
  }, {
    name: "Cookies",
    link: "/termeni/cookies"
  }],
  mobileOnly: false
}, {
  type: 'item',
  menuItem: {
    title: 'Contact',
    link: '/contact'
  },
  icon: faPhoneAlt,
  mobileOnly: true
}];
export var accountMenuList = [{
  type: 'item',
  menuItem: {
    title: 'Contul meu',
    link: '/'
  },
  icon: faUserCircle,
  mobileOnly: false
}, {
  type: 'item',
  menuItem: {
    title: 'Comenzi',
    link: '/user/comenzi'
  },
  icon: faReceipt,
  mobileOnly: false
}, {
  type: 'list',
  menuItem: {
    title: 'Credite'
  },
  options: [{
    name: 'Cereri',
    link: '/user/credite/cereri'
  }, {
    name: 'Biroul de Credit',
    link: '/user/biroul-de-credit'
  }],
  icon: faCoins,
  mobileOnly: false
}, {
  type: 'list',
  menuItem: {
    title: 'Asigurări RCA'
  },
  options: [{
    name: 'Polițe RCA',
    link: '/user/asigurari/rca'
  }, {
    name: 'Oferte RCA',
    link: '/user/asigurari/rca/oferte'
  }, {
    name: 'Comparații',
    link: '/asigurari-rca'
  }],
  icon: faCar,
  mobileOnly: false
}, {
  type: 'item',
  menuItem: {
    title: 'Recenzii',
    link: '/user/recenzii'
  },
  icon: faComments,
  mobileOnly: false
}, {
  type: 'list',
  menuItem: {
    title: 'Profil'
  },
  options: [{
    name: 'Newsletter',
    link: '/user/profil/newsletter'
  }, {
    name: 'Date personale',
    link: '/user/profil/info'
  }, {
    name: 'Schimbă parola',
    link: '/user/profil/password'
  }],
  mobileOnly: false,
  icon: faUserEdit
}];
export var accountLogoffMenuList = [{
  type: 'item',
  menuItem: {
    title: 'Login',
    link: '/login'
  },
  icon: faUserCircle,
  mobileOnly: false
}, {
  type: 'item',
  menuItem: {
    title: 'Cont nou',
    link: '/creeaza-cont'
  },
  icon: faUser,
  mobileOnly: false
}, {
  type: 'item',
  menuItem: {
    title: 'Asigurari RCA',
    link: '/asigurari-rca'
  },
  icon: faCar,
  mobileOnly: false
}];
export var leadMenuList = [{
  type: 'item',
  menuItem: {
    title: 'Contul meu',
    link: '/'
  },
  icon: faUserCircle,
  mobileOnly: true
}];