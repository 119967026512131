import _defineProperty from "/home/myaccountconso/web/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Button } from 'react-bootstrap';
import CookieOptionItem from './CookieOptionItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import styles from '../styles/about/CookieConsent.module.scss';

var CookieOptions = function CookieOptions(props) {
  var showNotice = props.showNotice,
      saveCookie = props.saveCookie,
      defaultPreferences = props.defaultPreferences;
  var cookieList = [{
    name: 'about',
    title: 'Preferinte cookies',
    description: __jsx(React.Fragment, null, "Po\u021Bi sa \xEE\u021Bi setezi preferin\u021Bele cu privire la cookies \xEEn sec\u021Biunile de mai jos. Mai multe informa\u021Bii despre modul \xEEn care utiliz\u0103m cookies, po\u021Bi citi \xEEn pagina cu ", __jsx(Link, {
      href: "".concat(process.env.NEXT_PUBLIC_WEB, "/termeni/cookies")
    }, __jsx("a", {
      title: "Politica Cookies",
      target: "_blank"
    }, "politica privind cookies"))),
    selected: true,
    button: 'disabled'
  }, {
    name: 'essential',
    title: 'Cookies esențiale',
    description: __jsx(React.Fragment, null, "Aceste cookies sunt esen\u021Biale pentru func\u021Bionarea website-ului \u0219i nu pot fi dezactivate. Acestea sunt utilizate pentru autentificarea \xEEn contul de utilizator \u0219i pentru a men\u021Bine website-ul stabil, sigur \u0219i functional."),
    selected: false,
    button: 'disabled'
  }, {
    name: 'analytics',
    title: 'Măsurare și analiză',
    description: __jsx(React.Fragment, null, "Aceste cookies ne permit s\u0103 contorizam vizitele \u0219i sursele de trafic cu scopul de a m\u0103sura \u0219i \xEEmbun\u0103t\u0103\u021Bi performan\u021Ba website-ului. Toate informa\u021Biile colectate sunt agregate \u0219i, prin urmare, anonime."),
    selected: false,
    button: defaultPreferences ? defaultPreferences.analytics == 0 ? 'off' : 'on' : 'off'
  }, {
    name: 'personal',
    title: 'Personalizare',
    description: __jsx(React.Fragment, null, "Aceste cookies ne permit s\u0103 personalizam experien\u021Ba de utilizare a website-ului \u0219i s\u0103 salvam preferin\u021Bele tale pentru o utilizare mai facila a website-ului. \xCEn acest mod, vei putea gasi mai rapid informa\u021Biile care te intereseaza."),
    selected: false,
    button: defaultPreferences ? defaultPreferences.personal == 0 ? 'off' : 'on' : 'off'
  }];

  var _useState = useState(cookieList),
      menu = _useState[0],
      editMenu = _useState[1];

  var viewOption = function viewOption(title) {
    var item = menu.find(function (item) {
      return item.title == title;
    });
    var newList = menu.map(function (item) {
      if (item.title == title) {
        return _objectSpread(_objectSpread({}, item), {}, {
          selected: true
        });
      } else {
        if (item.selected) {
          return _objectSpread(_objectSpread({}, item), {}, {
            selected: false
          });
        }

        return item;
      }
    });
    editMenu(newList);
  };

  var changeSelection = function changeSelection(title) {
    var newList = menu.map(function (item) {
      if (item.title == title) {
        var button = item.button == 'on' ? 'off' : 'on';
        return _objectSpread(_objectSpread({}, item), {}, {
          button: button
        });
      } else {
        return item;
      }
    });
    editMenu(newList);
  };

  var listHtml = menu.map(function (item, key) {
    return __jsx("li", {
      key: key
    }, __jsx(CookieOptionItem, {
      title: item.title,
      description: item.description,
      selected: item.selected,
      viewOption: viewOption,
      button: item.button,
      changeSelection: changeSelection
    }));
  });

  var saveCustomCookie = function saveCustomCookie() {
    var personal = menu.find(function (item) {
      return item.name == 'personal';
    });
    var analytics = menu.find(function (item) {
      return item.name == 'analytics';
    });
    var opt = {
      consented: 1,
      categories: {
        analytics: analytics.button == 'on' ? 1 : 0,
        personal: personal.button == 'on' ? 1 : 0
      },
      consentDate: new Date()
    };
    saveCookie(opt);
  };

  var saveAllCookie = function saveAllCookie() {
    var opt = {
      consented: 1,
      categories: {
        analytics: 1,
        personal: 1
      },
      consentDate: new Date()
    };
    saveCookie(opt);
  };

  return __jsx("dialog", {
    className: styles.root
  }, __jsx("div", {
    className: styles.dialogOverlay
  }), __jsx("div", {
    className: styles.consentPreferences
  }, __jsx("div", {
    className: "".concat(styles.logo, " d-flex justify-content-between")
  }, __jsx(Image, {
    src: "/images/icons/conso.png",
    width: 70,
    height: 21,
    alt: "Conso"
  }), __jsx(FontAwesomeIcon, {
    icon: faTimes,
    width: "16",
    height: "16",
    className: styles.link,
    onClick: showNotice
  })), __jsx("p", {
    className: "heading3 font-weight-bold"
  }, "Op\u021Biuni Cookies"), __jsx("div", {
    className: styles.preferenceContainer
  }, __jsx("ul", {
    className: styles.categoryGroup,
    role: "tablist"
  }, listHtml), __jsx("div", {
    className: "d-flex justify-content-around pt-3"
  }, __jsx(Button, {
    variant: "secondary",
    onClick: saveCustomCookie
  }, "Salveaz\u0103 op\u021Biunile"), __jsx(Button, {
    variant: "outline-secondary",
    onClick: saveAllCookie
  }, "Accept toate cookies")))));
};

export default CookieOptions;