var __jsx = React.createElement;
import React from 'react';
import Link from 'next/link';
import { v4 as uuidv4 } from 'uuid';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import NavDropdown from 'react-bootstrap/NavDropdown';
import styles from '../styles/header/Navbar.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

function NavbarLinks(props) {
  var menuList = props.menuList,
      selected = props.selected;
  return __jsx(Nav, {
    className: ""
  }, menuList.map(function (item, i) {
    return item.mobileOnly ? '' : item.type == 'item' ? __jsx(Link, {
      href: item.menuItem.link,
      passHref: true,
      key: uuidv4()
    }, __jsx(Nav.Link, {
      className: "pr-3 ".concat(selected == item.menuItem.title ? 'selected' : '')
    }, i == 0 && __jsx(FontAwesomeIcon, {
      icon: item.icon,
      className: "mr-1 green"
    }), item.menuItem.title)) : __jsx(NavDropdown, {
      title: item.menuItem.title,
      className: "d-flex pr-3 ".concat(selected == item.menuItem.title ? 'selected' : ''),
      key: uuidv4()
    }, __jsx("ul", {
      className: "".concat(styles.dropItem, " list-unstyled ")
    }, __jsx("li", {
      className: styles.topic
    }, "Op\u021Biuni"), item.options.map(function (item, index) {
      return __jsx("li", {
        className: "",
        key: uuidv4()
      }, index !== 0 && __jsx(NavDropdown.Divider, null), __jsx(Link, {
        href: item.link,
        passHref: true
      }, __jsx(NavDropdown.Item, null, __jsx(FontAwesomeIcon, {
        icon: faChevronRight,
        height: "16",
        width: "16",
        className: "mr-2 green"
      }), item.name)));
    })));
  }));
}

export default NavbarLinks;