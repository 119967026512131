var __jsx = React.createElement;
import React from 'react';
import Form from 'react-bootstrap/Form';
import styles from '../styles/about/CookieConsent.module.scss';

var CookieOptionItem = function CookieOptionItem(props) {
  var title = props.title,
      description = props.description,
      selected = props.selected,
      button = props.button,
      viewOption = props.viewOption,
      changeSelection = props.changeSelection;

  var handleView = function handleView() {
    viewOption(title);
  };

  var handleSelection = function handleSelection() {
    changeSelection(title);
  };

  var buttonHtml = button != 'disabled' && __jsx("div", {
    className: styles.consentButton
  }, __jsx(Form.Check, {
    type: "switch",
    id: title,
    label: button == 'on' ? 'Activ' : 'Inactiv',
    onClick: handleSelection,
    defaultChecked: button == 'on' ? true : false
  }));

  return __jsx(React.Fragment, null, __jsx("span", {
    className: "".concat(styles.categoryItem, " ").concat(selected ? styles.selected : ''),
    onClick: handleView
  }, title), selected && __jsx("div", {
    className: styles.categoryDescription
  }, __jsx("div", {
    className: "py-2 d-flex justify-content-between align-items-start"
  }, __jsx("span", {
    className: "heading3"
  }, title), buttonHtml), description));
};

export default CookieOptionItem;