
export const getCookie = (c_name) => {
    var i,x,y,ARRcookies=document.cookie.split(";");
    for (i=0;i<ARRcookies.length;i++)
    {
      x=ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));
      y=ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1);
      x=x.replace(/^\s+|\s+$/g,"");
      if (x==c_name)
        {
        return unescape(y);
        }
      }
}

export const setCookie = (c_name, value, exdays) => {
    let exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    let expiration = (exdays==null) ? "" : "; expires="+exdate.toUTCString();
    let path = ";path=/";
    let c_value = escape(value);

    let secure = '';
    let same = '';
    if ( process.env.NODE_ENV  == 'production') {
        secure = ";secure=1";
        same = "; sameSite=None";
    } 
    
    document.cookie= c_name + "=" + c_value + expiration + path +  same + secure ; 
}

export const setPHPCookie = (cookie) => { 

  let newCookie;
  if (process.env.NODE_ENV == 'development') {
    newCookie = cookie.replace('httponly', '');
    document.cookie = newCookie ; 
  } else {
    newCookie = cookie.replace('httponly', '');
  }
  document.cookie = newCookie;
}

export const getCookieFromString = (c_name, string) => {
  var i,x,y,ARRcookies=string.split(";");
  for (i=0;i<ARRcookies.length;i++)
  {
    x=ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));
    y=ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1);
    x=x.replace(/^\s+|\s+$/g,"");
    if (x==c_name)
      {
      return unescape(y);
      }
    }
}
