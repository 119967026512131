var __jsx = React.createElement;
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { loadCookie } from './cookieSlice';
import CookieNotice from './CookieNotice';
import CookieOptions from './CookieOptions';
import { getCookie, setCookie } from './cookieTools';

var CookieConsent = function CookieConsent(props) {
  var _useState = useState(null),
      step = _useState[0],
      editStep = _useState[1];

  var showOptions = function showOptions() {
    editStep('options');
  };

  var showNotice = function showNotice() {
    editStep('notice');
  };

  var cookieState = useSelector(function (state) {
    return state.cookie;
  });
  var dispatch = useDispatch();
  var router = useRouter();
  useEffect(function () {
    if (cookieState.status == 'init') {
      checkCookie();
    }
  }, []);

  var checkCookie = function checkCookie() {
    var ckconso = getCookie('ckconso');

    if (!ckconso) {
      editStep('notice');
    } else {
      var cookieOpt = JSON.parse(ckconso);
      dispatch(loadCookie(cookieOpt));

      if (cookieOpt.consented == 0) {
        editStep('notice');
      }
    }
  };

  var saveCookie = function saveCookie(opt) {
    setCookie('ckconso', JSON.stringify(opt), 90);
    editStep(null);
    router.reload(window.location.pathname);
  };

  var showStep = step == 'notice' ? __jsx(CookieNotice, {
    showOptions: showOptions,
    saveCookie: saveCookie
  }) : __jsx(CookieOptions, {
    showNotice: showNotice,
    saveCookie: saveCookie
  });
  return step && showStep;
};

export default CookieConsent;