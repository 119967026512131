var __jsx = React.createElement;
import React from 'react';
import Image from 'next/image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from '../../styles/cart/CartHeader.module.scss';

var CartItemInfo = function CartItemInfo(props) {
  var product = props.product;
  var image = {
    file: product.provider.logo,
    height: product.provider.logoSize ? product.provider.logoSize.height : null,
    width: product.provider.logoSize ? product.provider.logoSize.width : null,
    title: product.provider.societate_nume
  };

  if (image.file) {
    var ratio = image.width / image.height;

    if (image.width > 75) {
      image.width = 75;
      image.height = 75 / ratio;
    }
  }

  var item = {
    name: "Asigurare RCA pe ".concat(product.vehicle.cerere.durata, " luni"),
    info: "Clasa bonus-malus: ".concat(product.clasa_bm).concat(product.decontareDirecta ? ', Decontare directa' : ''),
    price: product.prima,
    currency: 'Lei'
  };
  return __jsx("div", {
    className: "".concat(styles.cartSummary)
  }, __jsx("div", {
    className: styles.image
  }, image.file ? __jsx(Image, {
    src: image.file,
    width: image.width,
    height: image.height,
    title: image.title,
    className: "pr-2 ".concat(styles.image),
    layout: "fixed"
  }) : __jsx("p", {
    className: "pr-3"
  }, image.title)), __jsx("div", {
    className: "pr-3"
  }, __jsx("p", {
    className: styles.productName
  }, item.name), __jsx("p", {
    className: styles.productInfo
  }, item.info)), __jsx("div", {
    className: styles.price
  }, item.price, __jsx("span", {
    className: styles.currency
  }, "Lei")));
};

export default CartItemInfo;