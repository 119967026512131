var __jsx = React.createElement;
import React, { useState } from 'react';
import Link from 'next/link';
import Button from 'react-bootstrap/Button';
import styles from '../styles/header/Drawer.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faCoins } from '@fortawesome/free-solid-svg-icons';

var MobileSubmenu = function MobileSubmenu(props) {
  var submenu = props.submenu,
      closeMenu = props.closeMenu;

  var _useState = useState(false),
      isOpen = _useState[0],
      editIsOpen = _useState[1];

  var toggleMenu = function toggleMenu() {
    editIsOpen(!isOpen);
  };

  var submenuList = __jsx("div", null, __jsx("ul", {
    className: "".concat(styles.list, " ").concat(isOpen ? '' : 'd-none')
  }, submenu.options.map(function (item, i) {
    return __jsx("li", {
      key: i
    }, __jsx(Link, {
      href: item.link
    }, __jsx(Button, {
      variant: "link",
      onClick: closeMenu
    }, item.name)));
  })), __jsx("hr", {
    className: styles.divider
  }));

  return __jsx("li", null, __jsx(Button, {
    className: "btn btn-secondary",
    onClick: toggleMenu
  }, __jsx("span", null, __jsx(FontAwesomeIcon, {
    icon: submenu.icon,
    className: "mr-3"
  }), submenu.menuItem.title), __jsx(FontAwesomeIcon, {
    icon: isOpen ? faChevronDown : faChevronRight,
    className: "inline-block float-right"
  })), submenuList);
};

export default MobileSubmenu;