var __jsx = React.createElement;
import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Button } from 'react-bootstrap';
import styles from '../styles/about/CookieConsent.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';

var CookieNotice = function CookieNotice(props) {
  var showOptions = props.showOptions,
      saveCookie = props.saveCookie;

  var saveAllCookies = function saveAllCookies() {
    var opt = {
      consented: 1,
      categories: {
        analytics: 1,
        personal: 1
      },
      consentDate: new Date()
    };
    saveCookie(opt);
  };

  var rejectAllCookies = function rejectAllCookies() {
    var opt = {
      consented: 1,
      categories: {
        analytics: 0,
        personal: 0
      },
      consentDate: new Date()
    };
    saveCookie(opt);
  };

  return __jsx("dialog", {
    className: styles.root
  }, __jsx("div", {
    className: styles.dialogOverlay
  }), __jsx("div", {
    className: styles.container
  }, __jsx("div", {
    className: styles.consentRoot
  }, __jsx("p", {
    className: "heading3 text-center font-weight-bold"
  }, "E\u0219ti de acord cu cookie-urile op\u021Bionale?"), __jsx("p", {
    className: styles.infoText
  }, "Acestea ne ajut\u0103 sa \xEE\u021Bi oferim o experien\u021B\u0103 mai bun\u0103, s\u0103 \xEEn\u021Belegem cum utilizezi portalul \u0219i s\u0103 \xEEti oferim o navigare facil\u0103. Afl\u0103 mai multe \xEEn ", __jsx(Link, {
    href: "".concat(process.env.NEXT_PUBLIC_WEB, "/termeni/cookies")
  }, __jsx("a", {
    title: "Politica Cookies",
    target: "_blank"
  }, "Politica privind cookies")), "."), __jsx("div", {
    className: "d-flex justify-content-around flex-column flex-lg-row ".concat(styles.optButtons)
  }, __jsx(Button, {
    variant: "secondary",
    onClick: saveAllCookies
  }, "Accept"), __jsx(Button, {
    variant: "secondary",
    onClick: rejectAllCookies
  }, "Refuz"), __jsx(Button, {
    variant: "outline-secondary",
    onClick: showOptions
  }, __jsx(FontAwesomeIcon, {
    icon: faWrench,
    height: "16",
    width: "16",
    className: "mr-2"
  }), "Op\u021Biuni")))));
};

export default CookieNotice;