var __jsx = React.createElement;
import React from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { setStatus } from '../../cart/cartSlice';
import Link from 'next/link';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import styles from '../../styles/header/Navbar.module.scss';
import { v4 as uuidv4 } from 'uuid';
import CartItemInfo from './CartItemInfo';

var CartTab = function CartTab(props) {
  var cart = props.cart;
  var router = useRouter();
  var dispatch = useDispatch();

  var cartIcon = __jsx(React.Fragment, null, __jsx(FontAwesomeIcon, {
    icon: faShoppingCart,
    height: "16",
    width: "16",
    className: styles.cartSize
  }), cart.length > 0 && __jsx(Badge, {
    pill: true,
    className: styles.cartBadge
  }, cart.length));

  var goToCart = function goToCart(e) {
    dispatch(setStatus('init'));
    router.push('/cart/');
  };

  var cartList = __jsx(DropdownButton, {
    title: cartIcon,
    drop: "down",
    variant: "link",
    className: "order-1 order-lg-3 mr-2 ".concat(styles.dropCart, " d-none d-md-block"),
    menuAlign: "right"
  }, cart.length > 0 ? __jsx(React.Fragment, null, cart.map(function (item, k) {
    return __jsx(React.Fragment, {
      key: uuidv4()
    }, k > 0 && __jsx(Dropdown.Divider, null), __jsx(Dropdown.Item, {
      as: "div"
    }, __jsx(CartItemInfo, {
      product: item
    })));
  }), __jsx(Dropdown.Divider, {
    key: uuidv4()
  }), __jsx(Dropdown.Item, {
    as: "button",
    onClick: goToCart,
    className: "text-center ".concat(styles.showCart)
  }, "Vezi cosul", __jsx(FontAwesomeIcon, {
    icon: faChevronRight,
    height: "16",
    width: "16",
    className: "ml-2"
  }))) : __jsx(Dropdown.Item, {
    as: "div"
  }, "Cosul este gol."));

  var cartListMobile = __jsx(Link, {
    href: "/cart"
  }, __jsx("a", {
    title: "Cos cumparaturi",
    className: "d-block d-md-none ".concat(styles.dropCart, " mr-4")
  }, __jsx(FontAwesomeIcon, {
    icon: faShoppingCart,
    height: "16",
    width: "16",
    className: styles.cartSize
  }), cart.length > 0 && __jsx(Badge, {
    pill: true,
    className: styles.cartBadge
  }, cart.length)));

  return __jsx(React.Fragment, null, cartList, cartListMobile);
};

export default CartTab;